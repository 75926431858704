import * as axios from 'axios';

const createRoute = () => (process.env.NODE_ENV === 'development' ? 'http://localhost' : '') + '/predict-check-answer/';
const createBody = (req) => ({
    question: req.textQuestion,
    answer: req.textAnswer
});
const createCfg = () => {
    return {
        headers: {
            "Content-Type": "application/json",
        }
    }
}

export async function predictCheckAnswer(req){
    try{
        const res = await axios.post(createRoute(), createBody(req), createCfg());
        console.log("predictCheckAnswer", res)
        return res;
    }
    catch(err){
        return null;
    }
}
