import * as axios from 'axios';

const createRoute = () => (process.env.NODE_ENV === 'development' ? 'http://localhost' : '') + '/parse-search-google/';
const createBody = (req) => {
    const formData = new FormData;
    formData.append('data', req)
    return formData;
};
const createCfg = () => {
    return {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
    }
}

export async function parseGoogleSearch(req) {
    try {
        return await axios.post(createRoute(), createBody(req), createCfg());
    } catch (err) {
        return null;
    }
}
