import {Header} from "./layouts/default/Header";
import {Main} from "./pages/main";
import {Footer} from "./layouts/default/Footer";
import {BrowserRouter, Route, Routes} from "react-router-dom";

export default function App() {
    return (
        <BrowserRouter>
            <Header/>
            <Routes>
                <Route path="/" element={<Main/>}>
                    <Route path=":lang" element={<Main/>}/>
                </Route>
            </Routes>
            <Footer/>
        </BrowserRouter>
    );
}
