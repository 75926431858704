import * as axios from 'axios';

const createRoute = () => (process.env.NODE_ENV === 'development' ? 'http://localhost' : '') + '/api/tts';
const createBody = (req) => JSON.stringify({data: req});
const createCfg = (voice) => {
    return {
        responseType: 'arraybuffer',
        headers: {
            "Content-Type": "application/json",
            "Voice": voice,
        }
    }
}

export async function convertTextToSound(req, voice = 'ermil') {
    try {
        return await axios.post(createRoute(), createBody(req), createCfg(voice));
    } catch (err) {
        return null;
    }
}
