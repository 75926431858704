export const Icons = {
    ICON_PLAY: `<svg width="30" height="30" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="20" cy="20" r="20" fill="#74d414"/>
                    <path d="M30 20L16 29.4L16 10.6L30 20Z" fill="white"/>
                </svg>`,
    ICON_PAUSE: `<svg width="30" height="30" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#74d414">
                    <path d="M256,0C114.617,0,0,114.615,0,256s114.617,256,256,256s256-114.615,256-256S397.383,0,256,0z M224,320  c0,8.836-7.164,16-16,16h-32c-8.836,0-16-7.164-16-16V192c0-8.836,7.164-16,16-16h32c8.836,0,16,7.164,16,16V320z M352,320  c0,8.836-7.164,16-16,16h-32c-8.836,0-16-7.164-16-16V192c0-8.836,7.164-16,16-16h32c8.836,0,16,7.164,16,16V320z"/>
                 </svg>`,
    ICON_STOP: `<svg width="30" height="30" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="20" cy="20" r="20" fill='#74d414'/>
                <rect x="12.5714" y="12.5714" width="14.8571" height="14.8571" fill="white"/>
            </svg>`,
    ICON_HAND: `<svg width="30" height="30" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="20" cy="20" r="20" fill="#74d414"/>
                    <path d="M13.9917 31.1169C13.9764 30.2514 13.8458 29.3923 13.6028 28.5608C13.3652 27.7479 13.2447 26.9064 13.2447 26.0595L13.2448 14.9867C13.2447 14.7264 13.346 14.4819 13.5298 14.298C13.718 14.1102 13.9682 14.0089 14.2341 14.0131C14.7623 14.0214 15.192 14.4719 15.1921 15.0176L15.1923 19.6129C15.1923 19.7242 15.2374 19.8251 15.3103 19.898C15.3833 19.971 15.4841 20.0161 15.5954 20.0161C15.8181 20.0161 15.9986 19.8356 15.9985 19.6129L15.9985 10.1334C15.9985 9.81629 16.122 9.51824 16.3462 9.29408C16.5704 9.06989 16.8684 8.94639 17.1855 8.94643C17.5025 8.94639 17.8006 9.06989 18.0247 9.29404C18.2489 9.51819 18.3723 9.81629 18.3723 10.1333L18.3723 18.7868C18.3724 19.0095 18.5529 19.1899 18.7755 19.1899C18.9982 19.1899 19.1786 19.0094 19.1786 18.7868L19.1788 8.49479C19.1788 7.84028 19.7112 7.30781 20.3657 7.30785C21.0201 7.30781 21.5527 7.84032 21.5527 8.4948L21.5525 18.117C21.5524 18.2004 21.5778 18.278 21.6213 18.3424C21.6937 18.4497 21.8165 18.5203 21.9557 18.5203C22.1784 18.5203 22.3589 18.3399 22.3589 18.1172L22.3589 9.8612C22.3609 9.20852 22.8924 8.67812 23.5456 8.67812C23.8625 8.67807 24.1608 8.80153 24.3854 9.02564C24.6094 9.24966 24.7329 9.54784 24.7329 9.86493L24.7329 19.5757L24.7329 23.9055C24.7329 24.0168 24.778 24.1176 24.8509 24.1905C24.9239 24.2635 25.0247 24.3086 25.136 24.3086C25.3587 24.3086 25.5392 24.1281 25.5391 23.9055L25.5391 19.5757C25.5391 19.1929 25.6883 18.833 25.9589 18.5623C26.2296 18.2916 26.5896 18.1425 26.9724 18.1425C27.4171 18.1425 27.7788 18.5043 27.7788 18.9489L27.7788 24.1844C27.7788 25.7503 25.1543 29.5733 23.9949 31.1169L13.9917 31.1169Z" fill="white"/>
                 </svg>`,
    ICON_MICROPHONE: `<svg
                            xmlns="http://www.w3.org/2000/svg"
                            x="30px"
                            y="30px"
                            viewBox="0 0 1000 1000"
                            fill="#74d414"
                            style="width: 30px"
                            xml:space="preserve">
                            
                        <path d="M500,10C229.4,10,10,229.4,10,500c0,270.6,219.4,490,490,490c270.6,0,
                        490-219.4,490-490C990,229.4,770.6,10,500,10z M387,277.9c0-64.7,50.6-117.1,
                        113.1-117.1c62.5,0,113.1,52.4,113.1,117.1v234.2c0,64.7-50.6,117.1-113.1,
                        117.1c-62.5,0-113.1-52.4-113.1-117.1V277.9z M631,838.7H369.5V801h111.9v-92.4l37.7,
                        0.1V801H631V838.7z M684.8,508.1c0,107.6-83.7,194.8-187,194.8c-103.3,
                        0-187-87.2-187-194.8V405.8h38.5v113.1c0,83.3,67.5,150.8,150.8,150.8c83.3,0,
                        150.8-67.5,150.8-150.8V405.8h34V508.1L684.8,508.1z"/>
                    </svg>`,
    ICON_GET_BACK: `<svg 
                        width="40" 
                        height="40" 
                        viewBox="0 0 40 40" 
                        fill="none" 
                        xmlns="http://www.w3.org/2000/svg"
                        >
                        <circle 
                                cx="20" 
                                cy="20" 
                                r="20" 
                                fill="#74D414"
                                />
                        <path
                            d="M32.5722 31.3935L31.9412 30.3337C27.0934 22.1882 20.4584 22.6879 18.8344
                                22.9514V29.371L8.23529 18.753L18.8344 8.23529V14.555C18.8885 14.555 18.9422
                                14.5546 18.9963 14.5546C24.8864 14.5546 27.6049 17.0317 27.7216 17.1408C34.2065
                                22.5359 32.8221 30.1069 32.8075 30.1827L32.5722 31.3935ZM20.2372 21.9247C22.966
                                21.9247 27.9917 22.8296 31.9981 28.675C32.0584 26.3824 31.5765 21.5717 27.0995
                                17.8462L27.0985 17.8453C27.0217 17.7768 24.2731 15.3231 18.3784 15.5051L17.8937
                                15.5192V10.4954L9.56795 18.7558L17.8933 27.0962V22.1957L18.2509 22.1072C18.2763
                                22.1006 19.0335 21.9247 20.2372 21.9247Z"
                            fill="white"/>
                        <path
                            d="M20.2372 21.9247C22.966 21.9247 27.9917 22.8296 31.9981 28.675C32.0584 26.3824 31.5765
                                21.5717 27.0995 17.8462L27.0985 17.8453C27.0217 17.7768 24.2731 15.3231 18.3784
                                15.5051L17.8937 15.5192V10.4954L9.56795 18.7558L17.8933 27.0962V22.1957L18.2509
                                22.1072C18.2763 22.1006 19.0335 21.9247 20.2372 21.9247Z"
                            fill="white"/>
                    </svg>`,
    SVG_MASK_CDO: `<svg className="clip-svg">
                        <defs>
                            <clipPath id="clipping" clipPathUnits="objectBoundingBox">
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M272.216 136.856L260.007 124.61C242.747 107.296 216.573 95.6779 190.867
                                        95.6779C159.608 95.6779 135.499 106.76 119.131 124.227C102.637 141.828
                                        92.8616 167.153 92.8616 197.439C92.8616 259.368 136.037 299.2 190.867
                                        299.2C216.573 299.2 242.747 287.581 260.007 270.268L272.216 258.022L337.232
                                        323.239L325.099 335.41C291.582 369.03 243.209 391.478 190.867
                                        391.478C86.8368 391.478 0.761719 316.153 0.761719 197.439C0.761719
                                        86.4172 79.1686 3.39995 190.867 3.39995C243.209 3.39995 291.582
                                        25.8478 325.099 59.4679L337.232 71.6389L272.216 136.856ZM353.274
                                        6.80003H428.135C469.642 6.80003 523.598 11.8771 567.591 38.6356C613.123
                                        66.3309 645.357 115.611 645.357 197.439C645.357 277.497
                                        614.039 326.707 569.046 354.854C525.491 382.1 471.527 388.078
                                        428.135 388.078H353.274V6.80003ZM553.256 194.249V194.039C553.256
                                        163.184 545.246 142.858 534.628 129.165C523.901 115.331 509.348
                                        106.771 493.848 101.588C478.271 96.3798 462.366 94.8084 450.104
                                        94.6132C448.446 94.5868 446.866 94.5857 445.374
                                        94.6033V296.837C446.99 296.853 448.71 296.848 450.519 296.813C463.045
                                        296.568 479.243 294.898 495.004 289.504C510.682 284.138 525.284 275.327
                                        535.86 261.139C546.336 247.086 554.033 226.193 553.261 194.458L553.256
                                        194.249ZM943.636 295.8V388.078H682.059V295.8H943.636ZM960.584
                                        146.439C960.584 227.31 895.293 292.878 814.542 292.878C733.791 292.878
                                        668.501 227.31 668.501 146.439C668.501 65.5682 733.791 0 814.542
                                        0C895.293 0 960.584 65.5682 960.584 146.439ZM868.483 146.439C868.483
                                        115.11 845.646 92.2779 814.542 92.2779C786.828 92.2779 760.601
                                        118.51 760.601 146.439C760.601 177.768 783.438 200.6 814.542
                                        200.6C845.646 200.6 868.483 177.768 868.483 146.439Z"
                                />
                            </clipPath>
                        </defs>
                    </svg>`,
    ICON_SCAN: `<svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    width="24"
                    height="24" 
                    viewBox="0 0 24 24"
                    fill="#74D414"
                >
                    <path d="M17 22V20H20V17H22V20.5C22 20.89 21.84 21.24 21.54 21.54C21.24 21.84 20.89 22 20.5
                            22H17M7 22H3.5C3.11 22 2.76 21.84 2.46 21.54C2.16 21.24 2 20.89 2 20.5V17H4V20H7V22M17 
                            2H20.5C20.89 2 21.24 2.16 21.54 2.46C21.84 2.76 22 3.11 22 3.5V7H20V4H17V2M7 2V4H4V7H2V3.5C2
                            3.11 2.16 2.76 2.46 2.46C2.76 2.16 3.11 2 3.5 2H7M10.5 6C13 6 15 8 15 10.5C15 11.38 14.75
                            12.2 14.31 12.9L17.57 16.16L16.16 17.57L12.9 14.31C12.2 14.75 11.38 15 10.5 15C8 15 6 13 6 
                            10.5C6 8 8 6 10.5 6M10.5 8C9.12 8 8 9.12 8 10.5C8 11.88 9.12 13 10.5 13C11.88 13 13 11.88 
                            13 10.5C13 9.12 11.88 8 10.5 8Z"
                    />
                </svg>`
}
