import * as React from 'react';
import {useEffect, useState} from 'react';
import {API} from "../../api";
import ReactHowler from 'react-howler';

export const PlayChunk = ({_chunk = [], onEndCB = () => {}}) => {

    const [chunk, setChunk] = useState([])
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTrack, setCurrentTrack] = useState("");
    const [currentTrackList, setCurrentTrackList] = useState([]);

    useEffect(() => {
        setChunk(_chunk)
    }, [_chunk])

    useEffect(() => {
    }, [isPlaying])

    useEffect(() => {
        if (chunk.length) {
            convertTextToSound()
        }
    }, [chunk])

    useEffect(() => {
        if (currentTrackList.length && currentTrack === "") {
            setCurrentTrack(currentTrackList.shift())
        }
    }, [currentTrackList])

    useEffect(() => {
        if (currentTrack !== "") {
            setIsPlaying(true)
        }
    }, [currentTrack])


    const convertTextToSound = async () => {
        setCurrentTrackList([])
        for (let item of chunk) {
            const tts = await API.convertTextToSound(item);
            setCurrentTrackList((item) => [...item, URL.createObjectURL(new Blob([tts.data], { type: 'audio/mpeg' }))])
        }
    }

    const onEndTrack = () => {
        if (currentTrackList.length) {
            setCurrentTrack(currentTrackList.shift())
        } else {
            setCurrentTrack("")
            setIsPlaying(false)
            if(onEndCB) {
                onEndCB()
            }
        }
    }

    return (

        currentTrack &&
        <ReactHowler
            src={currentTrack}
            playing={isPlaying}
            onEnd={onEndTrack}
            format={["mp3"]}
        />

    );
};
