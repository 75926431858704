const baseSoundPath = '/sounds/ru/lawyer';

export const lessonsRu = [
  {
    title: 'welcome',
    duration: 4,
    isNeedRecording: true,
    isNeedRecognize: false,
    question: 'Как зовут Вас?',
    src: '/sounds/lawyer/1.wav'
  },
  {
    title: 'l0',
    duration: 99,
    isNeedRecording: false,
    isNeedRecognize: false,
    src: `${baseSoundPath}/2.wav`
  },
  {
    title: 'l1',
    duration: 99,
    isNeedRecording: false,
    isNeedRecognize: false,
    src: `${baseSoundPath}/3.wav`
  },
  {
    title: 'l2',
    duration: 99,
    isNeedRecording: false,
    isNeedRecognize: false,
    src: `${baseSoundPath}/4.wav`
  },
  {
    title: 'l3',
    duration: 99,
    isNeedRecording: false,
    isNeedRecognize: false,
    src: `${baseSoundPath}/5.wav`
  },
  {
    title: 'l4',
    duration: 99,
    isNeedRecording: false,
    isNeedRecognize: false,
    src: `${baseSoundPath}/6.wav`
  },
  {
    title: 'l5',
    duration: 99,
    isNeedRecording: false,
    isNeedRecognize: false,
    src: `${baseSoundPath}/7.wav`
  },
  {
    title: 'l6',
    duration: 99,
    isNeedRecording: false,
    isNeedRecognize: false,
    src: `${baseSoundPath}/8.wav`
  },
  {
    title: 'l7',
    duration: 99,
    isNeedRecording: false,
    isNeedRecognize: false,
    src: `${baseSoundPath}/9.wav`
  },
];
