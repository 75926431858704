import * as React from 'react';
import {useEffect, useRef, useState} from "react";
import {MarqueeString} from "../MarqueeString";
import {useTranslation} from "react-i18next";

const originalItems = [
    "В каком году впервые была принята программа \"Цифровая экономика Российской Федерации\"",
    "Кто в Правительстве России по состоянию на май 2019 года является куратором программы \"Цифровая экономика\"",
    "Как называется координационный орган Правительства, курирующий программу \"Цифровая экономика\"",
    "Какая организация, ведомство или организационная структура выполняет функции проектного офиса программы \"Цифровая экономика\"",
    "На какой срок рассчитана реализация программы \"Цифровая экономика\"",
    "Какой объем бюджетных средств предусмотрен на реализацию программы \"Цифровая экономика\" до 2024 года",
    "Какой федеральный проект НЕ входит в состав программы \"Цифровая экономика Российской Федерации\"",
    "Сколько всего федеральных проектов входит в состав программы \"Цифровая экономика\"",
    "Какое из понятий НЕ используется в паспорте программы \"Цифровая экономика\" и паспортах федеральных проектов в ее составе",
    "Какое федеральное ведомство является одним из двух ключевых ответственных исполнителей национальной программы \"Цифровая экономика\"",
    "А какое другое федеральное ведомство является вторым ключевым ответственным исполнителем программы \"Цифровая экономика\"",
    "Как расшифровывается сокращение \"сквот\", часто встречающееся в материалах и публикациях по программе \"Цифровая экономика\"",
    "Какой из федеральных проектов в составе программы \"Цифровая экономика\" является самым дорогим по общему объему предусмотренных на его реализацию средств (бюджетных и внебюджетных)",
    "В каком федеральном проекте в качестве центра компетенции выступает Сбербанк России",
    "Какая технология не входит в перечень сквозных цифровых технологий (СЦТ) в проекте \"Цифровые технологии\"",
    "Сколько денег получит суммарно Национальный центр информатизации, выигравший 3 конкурса на разработку \"дорожных карт\" по сквозным технологиям - Большие данные, Технологии беспроводной связи и Промышленный интернет",
    "Сведения об окружающем мире, которые уменьшают имеющуюся степень неопределенности, неполноты знаний, отчужденные от их создателя и ставшие сообщения - это",
    "Процесс насыщения производства и всех сфер жизни и деятельности человека информацией",
    "Совокупность документов, оформленных по единым правилам, называется",
    "Технические показатели качества информационного обеспечения относятся к",
    "\"Субъективный показатель, характеризующий меру достаточности оцениваемой информации для решения предметных задач\"",
    "Система средств и способов сбора, передачи, накопления, обработки, хранения, представления и использования информации",
    "Под информационной технологией понимаются операции, производимые с информацией",
    "\"АИС, обеспечивающая информационную поддержку целенаправленной коллективной деятельности предприятия, − это\"",
    "Вид аналога собственноручной подписи, являющийся средством защиты информации",
    "\"Наиболее устойчивая к неисправностям отдельных узлов, и легко наращиваемая и конфигурируемая топология сети\"",
    "\"Система, в которой протекают информационные процессы, составляющие полный жизненный цикл информации\"",
    "\"Организация, осуществляющая физическое проектирование на основе существующей концепции ИС\"",
    "Целью автоматизации финансовой деятельности является",
    "Карты, классифицирующиеся по выполняемым ими финансовым операциям",
    "Адрес компьютера в сети, представляющий собой 32-разрядное двоичное число",
    "Электронная почта обеспечивает передачу данных в режиме",
    "\"Рекламный графический блок, помещаемый на Web-странице и имеющий гиперссылку на сервер рекламодателя\"",
    "Терминал, предназначенный для оплаты покупки с помощью карты",
    "Адресом электронного почтового ящика может являться",
    "Цель информационного обеспечения определяется",
    "Какая страна была источником развития Интернета",
    "Какой процент населения пользуется интернетом",
    "Условия функционирования стационарного интернета на базовом уровне",
    "Кто был мировым лидером почтовых сервисов в 2020 году",
    "В чем выражается влияние цифровой экономики на потребителей",
    "Что является ключевым фактором в хозяйственной деятельности в условиях цифровизации",
    "На какой период разработана Стратегии развития информационного общества в Российской Федерации",
    "Совокупность информационных ресурсов, созданных субъектами информационной сферы, средств взаимодействия таких субъектов, их информационных систем и необходимой информационной инфраструктуры",
    "Какие изменения являются следствием влияния цифровой экономики",
    "За счет чего происходит глобализация конкуренции",
    "Экономическая деятельность, основанная на платформенных компаниях, онлайн-системах, предоставляющих комплексные типовые решения - это",
    "В чем отличие автоматизации от цифровизации",
    "Какая отрасль является ключевой для цифровой экономики",
    "Что такое цифровая экономика",
    "Что такое онлайн-технологии",
    "Что такое электронная подпись",
    "Где можно получить электронную подпись",
    "Компьютерный вирус – это",
    "Для чего предназначены антивирусные программы",
    "Цифровой след это",
    "Какое сочетание информации можно отнести к персональным данным",
    "Как зарегистрировать электронный почтовый ящик",
    "Что можно сделать с помощью сервиса «мобильный банкинг»",
    "Что такое Интернет - банкинг",
    "Единая биометрическая система позволяет гражданину",
    "Что такое государственные услуги",
    "Что такое личный кабинет пользователя",
    "Что такое аутентификация",
    "Интернет - браузер это",
    "Социальная сеть – это",
    "Что такое биткоин",
    "Что такое майнинг",
    "Что такое блокчейн",
    "Что такое «Интернет вещей»",
    "Искусственный интеллект",
    "Что такое «облачные технологии»",
    "Что такое телемедицина",
    "Что такое электронные деньги",
    "Этапы и стадии развития цифровой экономики",
    "Суть цифровой трансформации заключается в",
    "Каковы главные особенности цифровой экономики",
    "Что отличает четвертую промышленную революцию от предыдущих",
    "Каковы основные проблемы внедрения цифровых технологий",
    "Киберфизические системы",
    "Приравнивает ли ЭП любой электронный документ к бумажному оригиналу",
    "Можно ли передать электронную подпись другому",
    "Возможно ли взломать квалифицированную подпись"
];

export const QuestionList = ({newQuestion = []}) => {

    const {t} = useTranslation();

    const [nextItem, setNextItem] = useState(0);
    const nextItemRef = useRef(null)

    const [items, setItems] = useState([])

    useEffect(() => {
        if (!items.length) {
            setItems(item => [...item, ...shuffleOriginalItems()])
        }
    }, [])

    useEffect(() => {
        if (newQuestion.length) {
            addItem(newQuestion[newQuestion.length - 1])
        }
    }, [newQuestion])

    useEffect(() => {
        nextItemRef.current = nextItem;
    }, [nextItem]);

    const insertItemCB = () => {
        setItems(item => [...item, ...shuffleOriginalItems()])
    }

    const nextItemCB = (nextItem) => {
        setNextItem(nextItem)
    }

    const addItem = (newQuestion) => {
        setItems(item => insertItem(item, nextItem, newQuestion))
    }

    const insertItem = (arr, index, newItem) => [...arr.slice(0, index), newItem, ...arr.slice(index)]

    const shuffleOriginalItems = () => {
        const _data = [...originalItems];
        _data.sort(() => Math.random() - 0.5);
        return _data;
    }
    return (
        items.length > 0 &&
        <div className="container_center">
            <h1>{t('NowAsk')}</h1>
            <MarqueeString items={items} insertItemCB={insertItemCB} nextItemCB={nextItemCB}/>
        </div>
    )
}
